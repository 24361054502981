import * as React from "react"
import { useState, useEffect } from "react"
import MainLayout from "../components/layouts/main-layout"
import ProductCard from "../components/ui/product-card"
import useCategories from "../hooks/use-categories"
import useProducts from "../hooks/use-products"

const Isotope = typeof window !== `undefined` ? require('isotope-layout') : null

const ProductsPage = () => {
  const products = useProducts().getAll()
  const categories = useCategories().getCategoryList()
  const subcategoryList = useCategories().getSubCategoriesList()

  const [isotope, setIsotope] = useState(null)
  const [filterKey, setFilterKey] = useState('one')
  const [subcategories, setSubcategories] = useState([])

  const handleFilter = (e) => {
    const filters = [...document.getElementsByClassName('btn-filter')]
    filters.forEach(item => {
      item.classList.remove('active')
    })
    e.target.classList.add('active')

    setFilterKey(e.target.dataset.filter)
    if(e.target.dataset.filter === 'all') {
      setSubcategories([])
    } else {
      if(categories.filter(c => c.id === e.target.dataset.filter).length) {
        setSubcategories(subcategoryList.filter(i => i.category === e.target.dataset.filter))
      }
    }
  }

  useEffect(() => {
    setIsotope(
      new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: 'masonry'
      })
    )
  }, [])

  useEffect(() => {
    if (isotope) {
      if (filterKey === 'one') {
        setTimeout(() => {
          isotope.arrange({ filter: '.all' })
          setFilterKey('all')
        }, 400)
      }
      isotope.arrange({ filter: `.${filterKey}` })
    }
  }, [isotope, filterKey])

  return (
    <MainLayout>
      <section className="container pt-3 pb-5">
        <h1 className="pt-5 pb-1 font-monserrat fs-3 text-shadow">Conoce nuestro portafolio de productos</h1>
        <p className="font-monserrat">Si tienes interés en algún producto, por favor escríbenos para enviarte más información y saber como realizar el proceso de compra.</p>
        <article className="pt-3 pb-0">
          <button type="button" aria-label="Filtrar producto" className="btn btn-default btn-filter active border-0 rounded-0 shadow-none px-4 py-2 me-2 mb-2" data-filter="all" onClick={handleFilter}>
            Todos
          </button>
          {categories?.map(({ id, name }) => (
            <button
              key={id}
              type="button"
              aria-label="Filtrar producto"
              className="btn btn-default btn-filter border-0 rounded-0 shadow-none px-4 py-2 me-2 mb-2 animate__animated animate__fadeIn"
              data-filter={id}
              onClick={handleFilter}>
              {name}
            </button>
          ))}
        </article>
        <article className="pb-1">
          {subcategories?.map(({ id, name }) => (
            <button
              key={id}
              type="button"
              aria-label="Filtrar producto"
              className="btn btn-default btn-filter border-0 rounded-0 shadow-none px-2 py-1 me-1 mb-1 animate__animated animate__fadeIn"
              data-filter={id}
              onClick={handleFilter}>
              {name}
            </button>
          ))}
        </article>
        <article className="grid products-container row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gx-4 gy-3 animate__animated animate__fadeIn animate__slow">
          {products?.map(({ id, name, excerpt, slug, image, categories }) => (
            <div className={`grid-item col all ${categories}`} key={id}>
              <ProductCard name={name} excerpt={excerpt} slug={slug} image={image} />
            </div>
          ))}
        </article>
      </section>
    </MainLayout>
  )
}

export default ProductsPage